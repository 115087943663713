import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import { State, City } from "country-state-city";
import { toast } from 'react-toastify';
import { Axios, ExternalAxios} from "./axiosInstances";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';
import { customerStore } from "../features/customersDataSlice";
// import { storeUserInfo,storeToken } from "../features/userInfoSlice";

function CreateCustomer() {
  const countryCode = "IN";
  const stateoptions = State.getStatesOfCountry(countryCode).map((state) => ({
    value: state.name,
    displayValue: state.name,
  }));

  const [selectState, setSelectState] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [contactNo, setContactNo] = useState();
  const [WhatsAppNo, setWhatsAppNo] = useState();
  const [spouseName , setSpouseName ] = useState();
  const [companySalesmanList , setCompanySalesmanList ] = useState();
  const [companySalesman , setCompanySalesman ] = useState();
  const [pinCode, setPinCode] = useState();
  const [email, setEmail] = useState();
  const [brithDate, setBrithDate] = useState();
  const [anniversaryDate, setAnniversaryDate] = useState();
  const [city, setCity] = useState();
  const [leadAssignTo, setLeadAssignTo] = useState();
  const [customerType, setCustomerType] = useState();
  const [customerTypeList, setCustomerTypeList] = useState([]);
  const [leadType, setLeadType] = useState();

  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const dispatch = useDispatch();
  const navigate =useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const selectedState = State.getStatesOfCountry(countryCode).find(
    (state) => state.name === selectState
  );

  const cityoptions = selectedState
    ? City.getCitiesOfState(countryCode, selectedState.isoCode).map((city) => ({
        value: city.name,
        displayValue: city.name,
      }))
    : [];

  const CustomerDataSave = async (customer) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await ExternalAxios.post(
        // "/api/v1/customerviewset/",
        "/create-customer",
        customer,
        config
      );
      if(data.status){
        toast.success(data.message)
        await CustomerListData()
      } else{
        toast.error(data.message)
      }

      setFirstName("");
      setLastName("");
      setCompanySalesman("");
      setContactNo("");
      setPinCode("");
      setSpouseName("");
      setWhatsAppNo("");
      setEmail("");
      setBrithDate("");
      setAnniversaryDate("");
      setCustomerType("");
      setLeadAssignTo("");
      setSelectState("");
      setCity("");
      setLeadType("");

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    try{
    CustomerDataSave({
      // name: firstName,
      // email: email,
      // mobile_no: WhatsAppNo,
      // birthday_date: brithDate,
      // anniversary_date: anniversaryDate,
      // customer_type: customerType,
      // lead_assigned_to: leadAssignTo,
      // state: selectState,
      // city: city,
      // lead_type: leadType,
      
      
      firstName: firstName,
      lastName:lastName,
      spouse_name:spouseName,
      contact_no:contactNo,
      pin_code:pinCode,
      email: email,
      whats_app_no: WhatsAppNo,
      birthday_date: brithDate,
      anniversary_date: anniversaryDate,
      customer_type: customerType,
      lead_assigned_to: companySalesman,
      state: selectState,
      city: city,
      lead_type: leadType,
      company_id:userInfo.data.iCompanyID,
      user_token:token

    });

  } catch (error) {
    console.error("Error:", error);
    toast.error('Failed to submit the form. Please try again.');
  }
  };

  const customerTypeData= async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await ExternalAxios.post(
        "/get-category-list",
        // "http://127.0.0.1:8000/api/v1/categoriesviewset/list_category/1/",
        {company_id:userInfo.data.iCompanyID,
        user_token:token},
        config
      );

      if (res.data && res.data.status) {
        const categories = JSON.parse(res.data.data[0].category)
        console.log(JSON.parse(res.data.data[0].category))
        setCustomerTypeList(categories);

      } else {
        setCustomerTypeList([]);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const salesmanListData= async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await ExternalAxios.post(
        "/get-salesman-list",
        {company_id:userInfo.data.iCompanyID,
        user_token:token},
        config
      );

      if (res.data && res.data.status && res.data.data) {
        setCompanySalesmanList(res.data.data.map(salesman => salesman.vSalesmanName));
      } else {
        setCompanySalesmanList([]);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const CustomerListData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await ExternalAxios.post(
        //   "/api/v1/customerviewset/list_users/",
        "/get-customer",
        {
          // page:1,
          // limit:10,
          company_id:userInfo.data.iCompanyID,
          user_token:token
        },
        config
      );
      
      if (res.data&&res.data.status) {
        dispatch(customerStore(res?.data))
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  useEffect(() => {
    
    if(!token){
      navigate('/error')
      return;
    }

    const fetchData = async () => {
      await customerTypeData(); 
      await salesmanListData(); 
    };

    fetchData();


  }, [token,navigate]); 

  return (
    <>
      <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
        <SideBar />

        <main className="page-content bg-gray-50 dark:bg-slate-800">
        <Header toggleSidebar={handleToggleSidebar}/>
          <div className="container-fluid relative px-3">
            <div className="layout-specing">
              <div className="shadow dark:shadow-slate-800 rounded bg-white dark:bg-slate-900">
                <div className="py-5 px-8">
                  <h5 className="text-lg font-semibold">Customer Details</h5>
                </div>
                <div className="py-5 px-8 border-t border-gray-100 dark:border-slate-800">
                  <form onSubmit={submitHandler}>
                    <div className="grid grid-cols-1 gap-5">
                    <div className="grid gap-3 grid-col-1  md:grid-cols-12">
                      <div className="xl:col-span-12 md:col-span-12">
                        <label className="font-semibold">Customer Type:</label>
                        <select
                          name="customer_type"
                          className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          value={customerType}
                          onChange={(e) => setCustomerType(e.target.value)}
                        >
                          <option value="" selected>
                            Select...
                          </option>
                          {customerTypeList&&customerTypeList.map((input, index) => (
                          <option  key={index} value={input}>{input}</option>
                          ))}
                        </select>
                      </div>
                      </div>
                    <div className="grid gap-3 grid-col-1  md:grid-cols-12">
                    <div className="xl:col-span-6 md:col-span-6">
                        <label className="form-label font-semibold">
                          First Name:
                        </label>
                        <input
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="First name"
                          id="first_name"
                          name="first_name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="xl:col-span-6 md:col-span-6">
                        <label className="form-label font-semibold">
                          Last Name:
                        </label>
                        <input
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="Last name"
                          id="last_name"
                          name="last_name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                      <div className="grid gap-3 grid-col-1  md:grid-cols-12">
                      <div className="xl:col-span-4 md:col-span-4">
                        <label className="form-label font-semibold">
                          Whats App No:
                        </label>
                        <input
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="Whats app number"
                          id="whats_app_no"
                          name="whats_app_no"
                          required
                          value={WhatsAppNo}
                          onChange={(e) => setWhatsAppNo(e.target.value)}
                        />
                      </div>
                      <div className="xl:col-span-4 md:col-span-4">
                        <label className="form-label font-semibold">
                          Contact No:
                        </label>
                        <input
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="Contact no"
                          id="contact_no"
                          name="contact_no"
                          value={contactNo}
                          onChange={(e) => setContactNo(e.target.value)}
                        />
                      </div>
                      <div className="xl:col-span-4 md:col-span-4">
                        <label className="form-label font-semibold">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="Email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      </div>
                      <div className="grid gap-3 grid-col-1  md:grid-cols-12">
                        <div className="xl:col-span-4 md:col-span-4">
                          <label className="form-label font-semibold">
                            Birth Date:
                          </label>
                          <input
                            type="date"
                            className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                            placeholder=""
                            id="birth_date"
                            name="birth_date"
                            value={brithDate}
                            onChange={(e) => setBrithDate(e.target.value)}
                          />
                        </div>
                        <div className="xl:col-span-4 md:col-span-4">
                          <label className="form-label font-semibold">
                            Anniversary Date:
                          </label>
                          <input
                            type="date"
                            className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                            placeholder="Phone number"
                            id="anniversary_date"
                            name="anniversary_date"
                            value={anniversaryDate}
                            onChange={(e) => setAnniversaryDate(e.target.value)}
                          />
                        </div>
                      <div className="xl:col-span-4 md:col-span-4">
                        <label className="form-label font-semibold">
                         Spouse Name:
                        </label>
                        <input
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="Spouse name"
                          id="spouse_name"
                          name="spouse_name"
                          value={spouseName}
                          onChange={(e) => setSpouseName(e.target.value)}
                          />
                          </div>
                      </div>
                      <div className="grid gap-3 grid-col-1  md:grid-cols-12">
                        <div className="xl:col-span-4 md:col-span-4">
                            <label className="font-semibold">State:</label>
                            <select
                              name="state"
                              id="state"
                              className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              required
                              value={selectState}
                              onChange={(e) => setSelectState(e.target.value)}
                            >
                              <option value="" selected>Select...</option>
                              {stateoptions.map((option, index) => {
                                return (
                                  <option key={index} value={option.value}>
                                    {option.displayValue}
                                  </option>
                                );
                              })}
                            </select>
                        </div>
                        <div className="xl:col-span-4 md:col-span-4">
                            <label className="font-semibold">City:</label>
                            <select
                              name="city"
                              id="city"
                              className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              onChange={(e) => setCity(e.target.value)}
                            >
                              <option value="" selected>Select...</option>
                              {cityoptions.map((option, index) => {
                                return (
                                  <option key={index} value={option.value}>
                                    {option.displayValue}
                                  </option>
                                );
                              })}
                            </select>
                        </div>
                      <div className="xl:col-span-4 md:col-span-4">
                        <label className="form-label font-semibold">
                         Pin Code:
                        </label>
                        <input
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder="Pin code"
                          id="pin_code"
                          name="pin_code"
                          value={pinCode}
                          onChange={(e) => setPinCode(e.target.value)}
                          />
                          </div>
                      </div>
                      <div className="grid gap-3 grid-col-1  md:grid-cols-12">
                      <div className="xl:col-span-6 md:col-span-6">
                        <label className="font-semibold">Lead Type:</label>
                        <select
                          name="lead_type"
                          className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          value={leadType}
                          onChange={(e) => setLeadType(e.target.value)}
                        >
                          <option value="" selected>
                            Select...
                          </option>
                          <option value="walk_in">Walk In</option>
                          <option value="reference">Reference</option>
                          <option value="social_media">Social Media</option>
                          <option value="google">Google</option>
                        </select>
                      </div>
                      <div className="xl:col-span-6 md:col-span-6">
                        <label className="font-semibold">Lead Assign:</label>
                        <select
                          name="company_salesman"
                          className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          value={companySalesman}
                          onChange={(e) => setCompanySalesman(e.target.value)}
                        >
                          <option value="" selected>
                            Select...
                          </option>
                          {companySalesmanList&&companySalesmanList.map((input, index) => (
                          <option  key={index} value={input}>{input}</option>
                          ))}
                        </select>
                      </div>
                      </div>
                      <div className="">
                        <button
                          type="submit"
                          className=" py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigobg-indigo-700 text-white rounded-md"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}

export default CreateCustomer;
