import React , { useState, useEffect } from 'react'
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import DataLoader from './DataLoader';
import { Axios, ExternalAxios} from "./axiosInstances";
import { toast } from "react-toastify";
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from "material-react-table";
import { useLocation,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const MessageHistory = () => {
  const [messageHistoryData,setMessageHistoryData]=useState([])
  const [chatMessageData,setChatMessageData]=useState([])
  const [dataLoader,setDataLoader]=useState(true);
  const [showSeletedUserMessageModal, setShowSeletedUserMessageModal] = useState(false);

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { batchName } = location.state || {};

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };


  const fetchMessageHistoryData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/whatsappviewset/message_history/${userInfo.data.iCompanyID}/?batch_name=${batchName}`,
        config
      );
      
      if (res.data) {
        setMessageHistoryData(res?.data);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };



  const handleInfoClick=(info)=>{
    Swal.fire({
      title: "Info",
      text: info,
      confirmButtonText: 'Ok'
      })
  }

  useEffect(() => {
      if(!token){
        navigate('/error')
      }
      if(batchName){
        setDataLoader(true)
        fetchMessageHistoryData()
        setDataLoader(false)
      }
      // else{
      //   Swal.fire({
      //     title: "Select batch first!",
      //     icon: 'warning',
      //     confirmButtonText: 'Batch'
      //     }).then((result) => {
            
      //         navigate("/batch-history")
            
      //     });
      // }
  }, [token,batchName,navigate, showSeletedUserMessageModal]);

  const columns = useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "recipient_name",
        size: 160,
      },
      {
        id: "mobile_no",
        header: "Mobile No",
        accessorKey: "message_to",
        size: 160,
      },
      {
        id: "template_name",
        header: "Template Name",
        accessorKey: "template_name",
        size: 160,
      },
      {
        accessorKey: 'status', // accessor is the "key" in the data
        header: 'Status',
        Cell: ({ cell }) => (
          cell.getValue() ? <span className="mdi mdi-check-bold" style={{ color: "green", fontSize: "1.5em"}}></span>:<span className="mdi mdi-close-thick" style={{ color: "red" , fontSize: "1.5em"}}></span>
        ),
      },
      {
        id: "info",
        header: "Info",
        accessorKey: "info",
        size: 160,
        Cell: ({ cell }) => (
          <button
          type='button'
            className='py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md z-10'
            onClick={() => handleInfoClick(cell.getValue())}
          >
            Info
          </button>
        ),
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data: messageHistoryData,
    state: {  isLoading: dataLoader },
    muiCircularProgressProps:{
      style: { color: '#4F46E5', thickness: 5, size: 55 },
    },
    muiSkeletonProps:{
      animation: 'pulse',
      height: 28,
    },
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enablePinning: true,
    enableRowSelection: false,
    getRowId: (row) => row.id, //give each row a more useful id
    // onRowSelectionChange: setRowSelection, //connect internal row selection state to your own

    // state: { rowSelection },
    // paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 180, //default size is usually 180
    },
    // enableColumnResizing: true,
    columnResizeMode: "onChange",
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [
        2,5, 10, 20, 50,
        {
          value: messageHistoryData?.length > 0 ? messageHistoryData?.length : 1,
          label: "All",
        },
      ],
      shape: "rounded",
      variant: "outlined",
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        borderRadius: "0",
        border: "none",
      },
    },
  });
  
  return (
    <>
      <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
          <SideBar />
          <main className="page-content bg-gray-50 dark:bg-slate-800">
            <Header toggleSidebar={handleToggleSidebar} />
            <div className="container-fluid relative px-3">
              <div className="layout-specing">
                <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="relative flex flex-col">
                    <MaterialReactTable table={table} />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </main>
      </div>
    </>
  )
}

export default MessageHistory
