import React , { useState, useEffect } from 'react'
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import { Axios, ExternalAxios} from "./axiosInstances";
import { toast } from "react-toastify";
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from "material-react-table";
import { useLocation,useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const ChatWithUser = () => {
  const [chatListData,setChatListData]=useState([])
  const [chatMessageData,setChatMessageData]=useState([])
  const [customerPhone,setCustomerPhone]=useState()
  const [dataLoader,setDataLoader]=useState(true);
  const [showSeletedUserMessageModal, setShowSeletedUserMessageModal] = useState(false);

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };


  const fetchChatListData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/whatsappviewset/retrieve_chat_list/${userInfo.data.iCompanyID}`,
        config
      );
      
      if (res.data) {
        setChatListData(res?.data);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const fetchChatMessageData = async (customerPhone) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/whatsappviewset/retrieve_chat/${userInfo.data.iCompanyID}/${customerPhone}`,
        config
      );
      
      if (res.data) {
        setChatMessageData(res?.data);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };



  const handleAddTemplateOpenModal = () => {
    setShowSeletedUserMessageModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleAddTemplateCloseModal = () => {
    setShowSeletedUserMessageModal(false);
    document.body.classList.remove("overflow");
  };

  const formatTime = (timestamp) => {
    return format(new Date(timestamp), 'p'); // 'p' is the pattern for time in 12-hour format with AM/PM
  };

  const handleChatClick= (message_to) => {
    setCustomerPhone(message_to)
    fetchChatMessageData(message_to)
    // handleAddTemplateOpenModal()
  }

  useEffect(() => {
      if(!token){
        navigate('/error')
      }
      console.log("inside",chatListData.length)

      if(!chatListData.length){
        console.log("inside if")
        setDataLoader(true)
        fetchChatListData()
        setDataLoader(false)
      }
  }, [token,navigate, showSeletedUserMessageModal]);

  const columns = useMemo(
    () => [
      {
        id: "recipient_name",
        header: "Name",
        accessorKey: "recipient_name",
        size: 160,
      },
      {
        id: "mobile_no",
        header: "Mobile No",
        accessorKey: "message_to",
        size: 160,
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      },
      {
        id: "chat",
        header: "Chats",
        accessorKey: "message_to",
        size: 160,
        Cell: ({ cell }) => (
          <button
          type='button'
            className='py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md z-10'
            onClick={() => handleChatClick(cell.getValue())}
          >
            Open Chat
          </button>
        ),
      },

    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data: chatListData,
    state: {  isLoading: dataLoader },
    muiCircularProgressProps:{
      style: { color: '#4F46E5', thickness: 5, size: 55 },
    },
    muiSkeletonProps:{
      animation: 'pulse',
      height: 28,
    },
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enablePinning: true,
    enableRowSelection: false,
    getRowId: (row) => row.id, //give each row a more useful id
    // onRowSelectionChange: setRowSelection, //connect internal row selection state to your own

    // state: { rowSelection },
    // paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 180, //default size is usually 180
    },
    // enableColumnResizing: true,
    columnResizeMode: "onChange",
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [
        2,5, 10, 20, 50,
        {
          value: chatListData?.length > 0 ? chatListData?.length : 1,
          label: "All",
        },
      ],
      shape: "rounded",
      variant: "outlined",
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        borderRadius: "0",
        border: "none",
      },
    },
    enableRowActions: false, // Disable default row actions
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {handleChatClick(row.original.message_to)},
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light hover effect
        },
      }
    })
  });
  
  return (
    <>
      <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
          <SideBar />
          <main className="page-content bg-gray-50 dark:bg-slate-800">
            <Header toggleSidebar={handleToggleSidebar} />
            <div className="container-fluid relative px-3">
              <div className="layout-specing">
                <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="relative flex flex-col">
                  <div className='grid  sm:grid-cols-2  grid-cols-1 mt-6 gap-6'>
                    <div className='sm:col-span-1'>
                    <MaterialReactTable table={table} />
                    </div>
                    <div className='sm:col-span-1'>
                    {customerPhone&&
                    <div className="chat-container chat-container-large overflow-hidden ">
                      <div className="chat">
                        <div className="chat-header">
                          <div className="profile">
                            <div className="left">
                              <img src="static/assets/img/arrow.png" alt="Arrow" className="arrow" />
                            </div>
                            <div className="right">
                              <img src="static/assets/img/video.png" alt="Video Call" className="icon" />
                              <img src="static/assets/img/phone.png" alt="Phone Call" className="phone" />
                              <img src="static/assets/img/more.png" alt="More Options" className="icon" />
                            </div>
                          </div>
                        </div>
                        <div className="chat-box " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/static/assets/img/bg.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                        {chatMessageData&&chatMessageData.map((chatMessage) => (chatMessage.message_type=="sent" ?
                          (
                          <div className="chat-r">
                            <div className="sp"></div>
                            <div className="mess mess-r">
                              <div className="mess-content">
                              <pre>
                              {chatMessage.content.template.header_content && (
                                <>
                                  <pre className=' font-bold'>{chatMessage.content.template.header_content}</pre>
                                </>
                              )}
                              <pre>
                              {chatMessage.content.template.content}
                              </pre>
                              </pre>
                              <div className="check">
                                <span>{formatTime(chatMessage.timestamp)}</span>
                              </div>
                              </div>
                              <div className="p-2">
                              </div>
                            </div>
                          </div>
                          ):( 
                          <div className="chat-l">
                            <div className="mess">
                              <div className="mess-content">
                                <pre>{chatMessage.content}</pre>
                              <div className="check">
                                <span>{formatTime(chatMessage.timestamp)}</span>
                              </div>
                              </div>
                              <div className="p-2">
                              </div>
                            </div>
                            <div className="sp"></div>
                          </div>
                          )))}
                        </div>

                        <div className="chat-footer">
                          <img src="static/assets/img/emo.png" alt="Emoji" className="emo" />
                          <textarea placeholder="Message" readOnly></textarea>
                          <div className="icons">
                            <img src="static/assets/img/attach file.png" alt="Attach File" />
                            <img src="static/assets/img/camera.png" alt="Camera" />
                          </div>
                          <img src="static/assets/img/mic.png" alt="Mic" className="mic" />
                        </div>
                      </div>
                    </div>
                    }
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </main>
      </div>
    </>
  )
}

export default ChatWithUser
