import axios from "axios";


const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: "https://crm.suvarnakarsoftware.com/api/v1",
  });

  return instance; 
};

const createExternalAxiosInstance = () => {
  const instance = axios.create({
    baseURL: "https://suvarnakarsoftware.com/api/v1", 
  });

  return instance;
};

export const Axios = createAxiosInstance();
export const ExternalAxios = createExternalAxiosInstance();
