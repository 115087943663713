import React, { useState, useEffect } from "react";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import DataLoader from "./DataLoader";
import { Axios, ExternalAxios} from "./axiosInstances";
import { toast } from "react-toastify";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
const BatchHistory = () => {
  const isSidebarToggled = useSelector(
    (state) => state.sidebarToggle.isSidebarToggled
  );
  const [messageHistoryBatchList, setMessageHistoryBatchList] = useState([]);
  const  [dataLoader,setDataLoader]=useState(true);

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("slected batch", messageHistoryBatchList);
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const fetchMessageHistoryBatchList = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const res = await Axios.get(
        `/whatsappviewset/get_batches/${userInfo.data.iCompanyID}/`,
        config
      );
      console.log("res", res.data);
      if (res.data) {
        setMessageHistoryBatchList(res.data);
        // setMessageHistoryBatchList(["hi"]);
      } else {
        setMessageHistoryBatchList();
      }
    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleInfoClick = (batchName) => {
    navigate("/message-history", { state: { batchName } })
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'PPpp'); // Customizing the format as needed
  };

  useEffect(() => {

    if(!token){
      navigate('/error')
      return;
    }
    setDataLoader(true)
    fetchMessageHistoryBatchList();
    setDataLoader(false)

  }, [token,navigate]);

  const columns = useMemo(
    () => [
      {
        id: "batch_name",
        header: "Batch Name",
        accessorKey: "batch_name",
        size: 160,
        
      },
      {
        id: "created_at",
        header: "Created At",
        accessorKey: "created_at",
        size: 160,
        Cell: ({ cell }) => (
          <div>{formatDate(cell.getValue())}</div>
        ),
        enableSorting: true, // Enable sorting for this column
        sortDescFirst: true, // Sort descending first by default
      },
      {
        id: "template_name",
        header: "Template Name",
        accessorKey: "template_name",
        size: 160,
      },
      {
        id: "view",
        header: "View",
        accessorKey: "batch_name",
        size: 160,
        Cell: ({ cell }) => (
          <button
            type="button"
            className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md z-10"
            onClick={() => handleInfoClick(cell.getValue())}
          >
            View
          </button>
        ),
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data: messageHistoryBatchList,
    state: {  isLoading: dataLoader },
    muiCircularProgressProps:{
      style: { color: '#4F46E5', thickness: 5, size: 55 },
    },
    muiSkeletonProps:{
      animation: 'pulse',
      height: 28,
    },
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enablePinning: true,
    enableRowSelection: false,
    enableSorting: true,
    getRowId: (row) => row.id, //give each row a more useful id
    // onRowSelectionChange: setRowSelection, //connect internal row selection state to your own

    // state: { rowSelection },
    // paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 180, //default size is usually 180
    },
    // enableColumnResizing: true,
    columnResizeMode: "onChange",
    initialState: {
      sorting: [{ id: 'created_at', desc: true }], 
      showColumnFilters: false,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [
        2,
        5,
        10,
        20,
        50,
        {
          value:
            messageHistoryBatchList?.length > 0 ? messageHistoryBatchList?.length : 1,
          label: "All",
        },
      ],
      shape: "rounded",
      variant: "outlined",
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        borderRadius: "0",
        border: "none",
      },
    },
  });
  return (
    <>
      <div className={`page-wrapper ${isSidebarToggled ? "toggled" : ""}`}>
        <SideBar />
        <main className="page-content bg-gray-50 dark:bg-slate-800">
          <Header toggleSidebar={handleToggleSidebar} />
          <div className="container-fluid relative px-3">
            <div className="layout-specing">
              <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="relative flex flex-col p-3">
                  <MaterialReactTable table={table} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default BatchHistory;
